// import { firestoreAction } from 'vuexfire'
import _cloneDeep from 'lodash/cloneDeep'
import _forEach from 'lodash/forEach'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _merge from 'lodash/merge'
import _filter from 'lodash/filter'
import { bindCollection, bindDocument, unbind, setDoc, updateDoc, addDoc, deleteDoc, uploadFile, deleteFile } from '@/store/firebase'
import { putRecord } from '../localDb.js'
import { bindDefaultRoots, bindNewRoot, bindReservedIDs, getDoc, getAsset, outsetaCheckAccount, outsetaGetToken } from '../firebase.js'
import { LogEvent, LogSetOrg } from '../analytics'
import { updateSchema } from '../schema'
// import { cond } from 'lodash'
import router from '@/router'

const vuexModule = {
  state: {
    config: {},
    schema: {},
    reservedIDs: [],
    projections: [],
    integrations: [],
    org: {},
    unit: {},
    units: [],
    assets: {},
    images: {},
    reports: {},
    tags: {},
    files: {},
    devices: [],
    parents: {},
    recordCount: 0,
    count: {
      assets: '...',
      images: '...',
      files: '...',
      tags: '...'
    }
  },
  mutations: {
    SET_PARENTS(state, data) {
      state.parents = _cloneDeep(data);
    },
    SET_SCHEMA(state, data) {
      // console.log(data)
      state.schema = _cloneDeep(data);
    },
    RECORD_COUNT(state, value) {
      state.recordCount = value;
    },
    SET_COUNT(state, value) {
      if (typeof value.assets !== 'undefined') state.count.assets = value.assets
      if (typeof value.images !== 'undefined') state.count.images = value.images
      if (typeof value.tags !== 'undefined') state.count.tags = value.tags
    }
  },
  getters: {
    testOrg(state) {
      return (state.org || {}).name === 'Test Org'
    },
    tagByCode: (state) => (code) => { 
      let tag = _find(state.tags, (t) => { 
        return t.code === code || t.id === code
      })
      return tag || null
    },
    imageByID: (state) => (id) => {
      let image = state.images[id]
      if (image) return image
      let k = Object.keys(state.images).find(iid => {
        if (state.images[iid].imageID === id) return true
        if (state.images[iid].id === id) return true
        return false
      })
      return state.images[k] || false
    },
    assetBranch: (state, getters) => (id) => {
      // assetBranch() returns ids of the asset and all its children

      // The array starts from the assetID of specified asset and then adds children in order of their parentage. 
      // NOTE: the resultant order of returned ids is such that children are always noted after their parents
      // this feature is used by calling functions for various purposes (eg: deleting assets or displaying in heirachal order) 

      let a = getters.assetByID(id)
      let list = [a.assetID]
      // let rootAssets = _filter(state.assets, { 'root': a.root })
      // let children = (pid) => {
      //   let cArray = _filter(rootAssets, { 'parent': pid })
      //   cArray.forEach(c => {
      //     list.push(c.assetID)
      //     children(c.assetID)
      //   })
      // }
      let children = (pid) => {
        let cArray = state.parents[pid] || []
        list = list.concat(cArray)
        cArray.forEach(c => { children(c) })
      }
      children(id)
      return list
    },
    assetPath: (state, getters) => (id) => {
      // if(!options) options = { load: false }
      let tree = []
      let add = (id) => {
        let a = getters.assetByID(id)
        let label = a.attributes?.label || null
        let type = a.attributes?.type || null
        tree.push({
          id: a.id,
          assetID: a.assetID,
          parent: a.parent,
          label,
          type,
          display: (label || '') + ' | ' + type
        })
      }
      add(id)
      for(let i = 1; i <= 10; i++) {
        let prev = tree[i-1]
        if(!prev.parent) break
        add(prev.parent)
      }
      return tree
    },
    assetByID: (state) => (id) => {
      let asset = state.assets[id]
      if (asset) return asset
      let k = Object.keys(state.assets).find(aid => {
        let a = state.assets[aid]
        if (a.assetID === id) return true
        if (a.id === id) return true
        return false
      })
      return state.assets[k] || false
    },
    assetsByPoint: (state) => (id) => {
      let assets = _filter(state.assets, (a) => {
        return a.points.findIndex(p => { return p.id === id }) !== -1
      })
      return assets
    },
    reportsByAsset: (state, getters) => (id) => {
      let asset = getters.assetByID(id)
      if (!asset) return false
      let reports = []
      Object.values(state.reports).forEach(r => {
        if (r.assets.includes(asset.assetID)) reports.push(r)
      })
      reports.sort((a,b) => {
        return a.ts.seconds - b.ts.seconds
      })
      return reports
    },
    tagsByAsset: (state) => (id) => {
      let tags = {
        codes: [],
        str: ''
      }
      _forEach(state.tags, (t, code) => {
        if (parseInt(t.assetID) === parseInt(id)) {
          tags.codes.push(code)
          if (tags.str.length > 0) tags.str += ', '
          tags.str += code
        }
      })
      return tags
    },
    assetCondition: (state, getters) => (id) => {
      let schema = state.schema.merge
      if(!schema.attributes) return false
      let value = schema.attributes.find(a => { return a.type === '_cond'})
      let ts = schema.attributes.find(a => { return a.type === '_condTs'})
      let reports = getters.reportsByAsset(id)
      let condReports = _cloneDeep((reports|| []).filter(r => { return r.type === 'condition'}))
      condReports.sort((a, b) => {
        const aTs = a.ts.seconds;
        const bTs = b.ts.seconds;
        return bTs - aTs
      })
      let ind = condReports.findIndex(r => { return r.status?.status === 'approved' })
      if(ind === -1) ind = condReports.findIndex(r => { return r.status?.status === 'pending' })
      if(ind === -1 && condReports.length === 1) ind = 0
      if(ind === -1) return false
      
      let result = {
        report: condReports[ind],
        attributes: {}
      }
      if(ts) result.attributes[ts.key] = new Date(result.report.ts.seconds*1000).toISOString()
      if(value) result.attributes[value.key] = result.report.attributes.rating
      return result
    },
    deviceByID: (state) => (id) => {
      return state.devices.find(a => { return a.id === id })
    },
    newAssetID: (state) => (qty = 1) => {
      const assetIDs = new Set()
      for (const asset in state.assets) {
        assetIDs.add(state.assets[asset].assetID)
      }
      const reservedIDs = []
      for (const reservedID of state.reservedIDs) {
        reservedIDs.push(reservedID.id)
      }
      reservedIDs.sort()
      let ids = []
      for (const reservedID of reservedIDs) {
        if(ids.length >= qty) break
        if (!assetIDs.has(reservedID)) {
          if(qty === 1) return reservedID
          ids.push(reservedID)
        }
      }
      if(ids.length > 0) return ids
      return null
    },
    attributesByType: (state, getters) => {
      let schema = state.schema.merge
      let schemaAttributes = {}
      _forEach(schema.attributes, (a) => {
        schemaAttributes[a.key] = a
      })
      let commonAttributes = state.schema.std.common
      // console.log(schema, commonAttributes)
      let result = {}
      _forEach(schema.types, (type, key) => {
        let templateAtts = schema.templates[type.template]?.attributes || []
        let keys = _union(templateAtts, type.addAttrib)
        // if(key === 'gauge_press') console.log({ key, type, keys, templateAtts})
        let attributes = {}
        let tak_set = new Set()
        keys.forEach((k) => {
          let a = schemaAttributes[k] || null
          // if(key === 'gauge_press' && k === 'model') console.log({ k, tak, a})
          if(!a) return
          a = _cloneDeep(a)
          
          if(!a.type) {
            // handle / log this error so the user can be notified of errors in their schema
            console.log('missing type in schema attribute', a.key)
            LogEvent('system_error', { key: 'schema', detail: 'missing type in schema attribute', attribute: a.key })
            return
          }
          a.key = String(a.key).toLowerCase().trim()
          let tak = a.tak || a.key
          tak_set.add(tak)
          if(!attributes[tak]) {
            attributes[tak] = a
          }
          else if(a.key !== tak) {
            // overrite duplicate attribute definition with custom key
            attributes[tak] = a 
            // console.log('duplicate key, using for ' + tak, 'using: ' + a.key) 
          }
        })
        commonAttributes.forEach((k) => {
          if(!tak_set.has(k)) {
            let a = schemaAttributes[k] || null
            if(!a) return
            a = _cloneDeep(a)
            a.key = String(a.key).toLowerCase().trim()
            attributes.add(a)
          }
        })
        result[type.name] = Object.values(attributes)
        result[type.name].sort((a, b) => {
          return a.index - b.index
        })
      })
      // console.log(result)
      return result
    }
  },
  actions: {
    loggedIn({ dispatch, rootState }) {
      dispatch('bindApp')
      let orgID = rootState.Auth.configUser.org
      if(orgID) dispatch('switchOrg', orgID)
    },
    logout({ dispatch }) {
      unbind()
      dispatch('loading', false)
    },
    async switchOrg({ dispatch, commit }, id) {
      commit('SET_COUNT', { images: 0, assets: 0 })
      // console.log('switchOrg', id)
      commit('SET_INIT', null)
      await dispatch('bindOrg', id)
    },
    switchUnit({ commit, dispatch, rootState, getters, state }, id) {
      if (typeof id !== 'string') {
        // console.log(getters.orgSettings)
        id = getters.orgSettings.currentUnit || (state.units[0] || {}).id
      }
      if (id === undefined || rootState.Util.org === undefined) {
        console.log('missing id', rootState.Util.org, id)
        return
      }
      commit('SET_ROOT_CACHE', [])
      commit('SET_INIT', { org: true, unit: false, assets: false, files: false })
      dispatch('bindUnit', id)
    },
    bindApp: async ({ }) => {
      bindDocument({ key: 'Db.config', documentRef: ['config', 'webapp'] })
    },
    bindOrg({ commit, dispatch, rootState }, org) {
      return bindDocument({ key: 'Db.org', documentRef: ['orgs', org] })
      .then(async () => { 
        commit('SET_INIT', { org: true })
        const promises = []
        // promises.push(bindDocument({ key: 'Db.org', documentRef: ['orgs', org] }))
        promises.push(bindCollection({ key: 'Db.units', collectionRef: ['orgs', org, 'units'] }))
        promises.push(bindCollection({ key: 'Db.projections', collectionRef: ['orgs', org, 'projections'] }))
        promises.push(bindCollection({ key: 'Db.tags', collectionRef: ['orgs', org, 'tags'] }))
        promises.push(bindReservedIDs({ key: 'Db.reservedIDs', collectionRef: ['orgs', org, 'reservedIDs'], userID: rootState.Auth.configUser.id }))
        promises.push(bindCollection({ key: 'Db.integrations', collectionRef: ['orgs', org, 'integrations'] }))
        await Promise.all(promises)
        dispatch('switchUnit')
        dispatch('loading', ['records'])
        LogSetOrg()
        outsetaCheckAccount()
        outsetaGetToken()
        updateSchema()
      })
      .catch(err => { 
        console.log(err) 
        dispatch('loading', false)
        commit('SET_INIT', { failed: true })
        setTimeout(() => router.push('/teamselect'), 500)
        commit('SET_ALERT', { variant: 'error', notice: "Failed to load org" })
      })
    },
    async bindUnit({ commit, rootState }, id) {
      try {
        const promises = []
        promises.push(bindDocument({ key: 'Db.unit', documentRef: ['orgs', rootState.Util.org, 'units', id] })
          .then(() => { commit('SET_INIT', { unit: true }) }).catch(err => { console.log(err) }))
        promises.push(bindDefaultRoots({ documentRef: ['orgs', rootState.Util.org, 'units', id] })
          .then(() => { commit('SET_INIT', { images: true, assets: true, files: true }) }).catch(err => { console.log(err) }))
        promises.push(bindCollection({ key: 'Db.devices', collectionRef: ['orgs', rootState.Util.org, 'units', id, 'devices'] })
          .then(() => { commit('SET_INIT', { devices: true }) }).catch(err => { console.log(err) }))
        await Promise.all(promises)
        // console.log('Bind Unit complete'); 
        commit('SET_INIT', { complete: true })
      } catch (e) {
        console.warn(`ERROR loading ${loading}`)
        console.warn(e)
      }
    },
    bindRoot({ dispatch, state }, root) {
      if (root === null) return true
      bindNewRoot({ dispatch }, { key: 'bind-roots', documentRef: ['orgs', state.org.id, 'units', state.unit.id], root })
    },
    async getAsset({}, assetID) {
      let asset = await getAsset(assetID)
      return asset
    },
    getImage({ state }, imageID) {
      getDoc(['orgs', state.org.id, 'units', state.unit.id, 'images'], 'Db.images', ['imageID', '==', imageID])
    },
    getReport({ state }, reportID) {
      getDoc(['orgs', state.org.id, 'units', state.unit.id, 'reports', reportID], 'Db.reports')
    },
    setOrg({ state }, update) {
      let id = ((state.org || {}).id || 'demo')
      if("schema" in update && !state.org.schema && state.unit.schema) {
        let newSchema = _merge({}, state.unit.schema, update.schema)
        update.schema = newSchema
      }
      setDoc(['orgs', id], update)
    },
    setUnit({ state }, { id, update }) {
      if (id === undefined) console.warn('id undefined')
      setDoc(['orgs', state.org.id, 'units', id], update)
    },
    async setAsset({ state, getters }, { id, update }) {
      if (id === undefined) console.warn('id undefined')
      let asset = getters.assetByID(id)
      if(Object.keys(update.attributes || {}).length === 0) delete update.attributes
      let result = await setDoc(['orgs', state.org.id, 'units', state.unit.id, 'assets', asset.id], update)
      return result
    },
    addFile({ state }, { data, file }) {
      if (typeof data.src !== 'string') {
        addDoc(['orgs', state.org.id, 'units', state.unit.id, 'files'], data)
      } else if (!data.src.startsWith('gs:')) {
        addDoc(['orgs', state.org.id, 'units', state.unit.id, 'files'], data)
      } else {
        let dbPath = data.src.split('/')
        if (dbPath.length > 3) return console.warn('invalid GS path')
        if (dbPath[2] !== data.fileID) return console.warn('invalid GS path')
        let folder = dbPath[1]
        uploadFile(folder + '/' + data.fileID, file).then((response) => {
          if (response.state === "success") {
            putRecord({ data: file, ts: response.ts, objStore: folder, key: data.fileID })
              .then(() => {
                addDoc(['orgs', state.org.id, 'units', state.unit.id, 'files'], data)
              })
          } else console.warn("addFile ERROR:", response)
        })
      }
    },
    setMap({ state }, { id, data }) {
      let update = { maps: {} }
      update.maps[id] = Object.assign((state.unit.maps || {})[id] || {}, data)
      setDoc(['orgs', state.org.id, 'units', state.unit.id], update)
    },
    setImage({ state, getters, dispatch, commit }, { data, file }) {
      // console.log("Db.setImage", data, file)
      let exist = getters.imageByID(data.imageID)
      let existId = exist ? exist.id : false
      let storageFiles = []
      let error = false
      if (file) {
        if (Array.isArray(data.src)) {
          data.src.forEach((src) => {
            if (typeof src !== 'string') {
              error = 'INVALID CALL TO ADD IMAGE: element in src array must be string'
              return console.warn(error)
            }
            if (src.startsWith('gs:')) {
              storageFiles.push(src.substring(4))
            }
          })
        } else if (typeof data.src === 'string') {
          if (data.src.startsWith('gs:')) storageFiles.push(data.src.substring(4))
        }
      }
      // console.log(storageFiles)
      let setData = () => {
        if (!error) {
          let afterAssets = data.assets
          if (afterAssets) {   // afterAssets may not exist if we are not changing asset list
            let beforeAssets = exist.assets || []
            afterAssets.forEach(a => {
              let asset = getters.assetByID(a)
              let imageSet = new Set(asset.images || [])
              imageSet.add(data.imageID)
              dispatch('updateAsset', { id: asset.id, update: { images: [...imageSet] } })
            })
            beforeAssets.forEach(a => {
              if (!afterAssets.includes(a)) {
                let asset = getters.assetByID(a)
                let imageSet = new Set(asset.images || [])
                imageSet.delete(data.imageID)
                dispatch('updateAsset', { id: asset.id, update: { images: [...imageSet] } })
              }
            })
          }
          if (existId) setDoc(['orgs', state.org.id, 'units', state.unit.id, 'images', existId], data)
          else addDoc(['orgs', state.org.id, 'units', state.unit.id, 'images'], data)
          dispatch('pushImageCache', data.imageID)
        }
      }

      let promiseArray = []
      if (storageFiles.length === 1 && !Array.isArray(file)) file = [file]
      if (storageFiles.length > 0) {
        if (!Array.isArray(file)) {
          error = "Expected array 'files'"
          return console.warn(error)
        }
        if (storageFiles.length !== file.length) {
          error = "ADDIMAGE: 'src' and 'files' different length"
          return console.warn(error)
        }
        storageFiles.forEach((src, i) => {
          if (file[i] !== null) {
            let dbPath = src.split('/')
            if (dbPath[1] !== data.imageID) return console.warn('invalid GS path: imageID doesnt match')
            promiseArray.push(
              uploadFile(src, file[i])
                .then((response) => {
                  return putRecord({ data: file[i], ts: response.ts, objStore: 'images', key: data.imageID })
                    .then(() => {
                      return response
                    })
                })
                .catch(err => { console.warn(err) })
            )
          }
        })
      }
      if (promiseArray.length > 0) {
        Promise.all(promiseArray).then((values) => {
          let success = true
          values.forEach(snap => {
            if (snap?.state === 'success') {
              data.ts = snap.ts
            } else {
              success = false
              error = "something went wrong with storage upload"
              console.warn(error, snap)
            }
          })
          if(success) setData()
          else {
            let notice = '<strong>Image Upload Failed</strong>'
            if(data.assets) notice += ` for Asset ${data.assets[0]}`
            if(data.report) notice += ` for Report ${data.report}`
            notice += `. <br />This is usually due to a missing network connection`
            commit('SET_ALERT', { 
              notice, 
              variant: 'danger', 
              timeout: 10000 
            })
          }
        })
      } else {
        setData()
      }
    },
    deleteImage({ state, getters, dispatch }, imageID) {
      let i = getters.imageByID(imageID)
      if (!i) i = state.images.find((record) => { return record.id === imageID })
      if (!i) return console.warn('could not find image: ', imageID)

      let assetIDs = _cloneDeep(i.assets || []);

      let deleteRecord = () => {
        assetIDs.forEach(assetID => {
          let asset = getters.assetByID(assetID);
          let update = { images: _cloneDeep(asset.images) };
          let ind = update.images.findIndex(iid => {
            return iid === i.imageID;
          });
          if (ind !== -1) {
            update.images.splice(ind, 1);
            dispatch("updateAsset", { id: asset.id, update });
          }
        });
        deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'images', i.id])
      }
      if (typeof i.src === 'string') {
        if (!i.src.startsWith('gs:')) return deleteRecord()
        let storageRef = i.src.substr(4)
        console.log('deleteImage', storageRef)
        deleteFile(storageRef)
          .then(result => {
            console.log(result)
            deleteRecord()
          })
          .catch((e) => {
            let msg = e.data.message
            if (String(msg).includes('storage/object-not-found')) {
              deleteRecord()
              return console.warn('image not found in storage')
            }
            console.warn(e)
          })
      }
      else if (Array.isArray(i.src)) {
        let promiseArray = []
        i.src.forEach((src, i) => {
          let dbPath = src.split('/')
          if (dbPath[2] !== data.imageID) console.warn('invalid GS path: imageID doesnt match')
          promiseArray.push(deleteFile(src))
        })
        Promise.all(promiseArray)
          .catch((e) => {
            if (e.code === 'storage/object-not-found') return 0
            console.warn(e)
          })
          .finally(() => {
            deleteRecord()
          })
      }
      else deleteRecord()
    },
    async addAsset({ state }, data) {
      let asset = await addDoc(['orgs', state.org.id, 'units', state.unit.id, 'assets'], data)
      LogEvent('add_asset')
      return asset
    },
    deleteAsset({ state, getters }, id) {
      // handle related reports: remove asset from list or delete reports if only refering to this asset
      let reports = getters.reportsByAsset(id)
      reports.forEach(r => {
        if(r.assets.length === 1) deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'reports', r.id])
        else {
          const assets = r.assets.slice() // take a copy
          const index = assets.indexOf(id)
          if (index > -1) assets.splice(index, 1)
          dispatch("updateReport", { id: r.id, update: { assets } })
        }
      })
      // handle related images: remove asset from list or delete images if only refering to this asset
      let images = _filter(state.images, { 'assets': [id] })
      images.forEach(i => {
        if(i.assets.length === 1) deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'images', i.id])
        else {
          const assets = i.assets.slice() // take a copy
          const index = assets.indexOf(id)
          if (index > -1) assets.splice(index, 1)
          dispatch("updateImage", { id: i.id, update: { assets } })
        }
      })

      deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'assets', id])
      LogEvent('delete_asset')
    },

    /* changeAssetID: applys relevant changes to images, reports and children 
    *  that are held in store. This is to ensure the UI experience is reasonable in
    *  an 'offline' situation. Further checks and changes are applied on server.
    */
    changeAssetId({ state, getters, dispatch }, { oldId, newId }) {
      return new Promise((resolve, reject) => {
        let a = getters.assetByID(oldId);

        if (!a) reject(`Could not find asset: ${oldId}`)

        let imageIDs = a.images || [];
        let children = _cloneDeep(state.parents[oldId] || [])
        let id = a.id

        imageIDs.forEach(imageID => {
          let image = getters.imageByID(imageID);
          if (image) {
            let update = { assets: [], hotSpots: _cloneDeep(image.hotSpots || []) };
            image.assets.forEach((aid) => {
              if (aid === a.assetID) update.assets.push(newId)
              else update.assets.push(aid)
            });
            update.hotSpots.forEach((hs, i) => {
              if (hs.assetID === a.assetID) update.hotSpots[i].assetID = newId
            });
            dispatch("updateImage", { id: image.id, update });
          }
        });

        for (const reportID in state.reports) {
          const assets = state.reports[reportID].assets.slice() // take a copy
          if (assets.includes(oldId)) {
            assets[assets.indexOf(oldId)] = newId
            dispatch("updateReport", { id: reportID, update: { assets } })
          }
        }

        children.forEach(childID => {
          let child = getters.assetByID(childID);
          if (child) dispatch("updateAsset", { id: child.id, update: { parent: newId } });
        });
        // console.log({ id, update: { assetID: newId } })
        dispatch("updateAsset", { id, update: { assetID: newId } });
        resolve()
      })
    },
    async addReport({ state }, data) {
      let result = await addDoc(['orgs', state.org.id, 'units', state.unit.id, 'reports'], data)
      LogEvent('add_report')
      return result
    },
    updateReport({ state }, { id, update }) {
      if (id === undefined) return console.warn('id undefined')
      updateDoc(['orgs', state.org.id, 'units', state.unit.id, 'reports', id], update)
    },
    setDevice({ state }, data) {
      setDoc(['orgs', state.org.id, 'units', state.unit.id, 'devices', data.id], data)
    },
    setTag({ state }, { id, update }) {
      setDoc(['orgs', state.org.id, 'tags', id], update)
    },
    deleteDevice({ state }, id) {
      deleteDoc(['orgs', state.org.id, 'units', state.unit.id, 'devices', id])
    },

    // The functions below use 'update' instead of set with merge.
    // Update can overwrite nested fields, but set with merge cannot.
    // It would be worth investigating this. 
    updateAsset({ state }, { id, update }) {
      if (id === undefined) return console.warn('id undefined')
        // console.log(['orgs', state.org.id, 'units', state.unit.id, 'assets', id], update)
      return updateDoc(['orgs', state.org.id, 'units', state.unit.id, 'assets', id], update)
    },
    updateImage({ state }, { id, update }) {
      if (id === undefined) return console.warn('id undefined')
      updateDoc(['orgs', state.org.id, 'units', state.unit.id, 'images', id], update)
    },
    updateUnit({ state }, { id, update }) {
      if (id === undefined) return console.warn('id undefined')
      updateDoc(['orgs', state.org.id, 'units', id], update)
    },
    updateOrg({ state }, update) {
      let id = state.org.id
      updateDoc(['orgs', id], update)
    },
  },
}

export default vuexModule