import store from "@/store"
import _debounce from 'lodash/debounce'
import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";
import _find from "lodash/find";

const setSchema = _debounce(schema => {
    store.commit('SET_SCHEMA', schema)
}, 500, { maxwait: 2000 })

const updateSchema = async () => {
    console.log("--- updateSchema ---")
    if(!store.state.Db.org || !store.state.Db.config) {
        console.log('schema: waiting on source data')
        setTimeout(updateSchema, 1000)
        return
    }
    let schema = {
        types: {},
        templates: {},
        lists: {},
        attributes: []
    }

    // PARSE ORG SCHEMA
    // create schema object, handling various legacy formats  
    let orgSchema = {
        types: {},
        templates: {},
        attributes: {},
        lists: {},
        groups: []
    }
    let dbOrgSchema = store.state.Db.org?.schema || {}
    if(typeof dbOrgSchema === 'string') dbOrgSchema = JSON.parse(dbOrgSchema)
    
    if(typeof dbOrgSchema.types === 'string') orgSchema.types = JSON.parse(dbOrgSchema.types)
    else if (typeof dbOrgSchema.types === 'object') orgSchema.types = dbOrgSchema.types

    if(typeof dbOrgSchema.templates === 'string') orgSchema.templates = JSON.parse(dbOrgSchema.templates)
    else if (typeof dbOrgSchema.templates === 'object') orgSchema.templates = dbOrgSchema.templates
    
    // parse legacy attribute definitions as object
    // for: orgSchema.templates.types and orgSchema.types.addAttrib
    _forEach(orgSchema.types, (t, k) => { 
        if(typeof t.addAttrib === 'string') t.addAttrib = JSON.parse(t.addAttrib)
        
        // convert legacy 'attributes' Array into an object
        if(Array.isArray(t.addAttrib)) {
            let addAttrib = {}
            t.addAttrib.forEach(o => {
                let key = o.tak || o.key || o
                addAttrib[key] = { include: 'Y' }
            })
            t.addAttrib = addAttrib
        }
    })
    _forEach(orgSchema.templates, (t, k) => { 
        if(typeof t.attributes === 'string') t.attributes = JSON.parse(t.attributes)
        
        // convert legacy 'attributes' Array into an object[tak] using as key as default tak
        if(Array.isArray(t.attributes)) {
            let attributes = {}
            t.attributes.forEach(o => {
                let key = o.tak || o.key || o
                attributes[key] = { include: 'Y' }
            })
            t.attributes = attributes
        }
    })

    if(typeof dbOrgSchema.attributes === 'string') orgSchema.attributes = JSON.parse(dbOrgSchema.attributes)
    if(Array.isArray(dbOrgSchema.attributes)) {
        // create Attributes object from legacy array 
        // use attribute.tak as object key and append 'index' attribute
        dbOrgSchema.attributes.forEach((i, a) => {
            let key = i.key
            let tak = i.tak || i.key
            orgSchema.attributes[key] = { ...i, tak, key }
            orgSchema.attributes[key].index = a
        })
    }
    else if (typeof dbOrgSchema.attributes === 'object') orgSchema.attributes = dbOrgSchema.attributes

    // orgSchema.lists might be an object or an array, parse either to object, 
    // make a copy so that it can be modified without affecting vuex state
    if(typeof dbOrgSchema.lists === 'string') orgSchema.lists = JSON.parse(dbOrgSchema.lists)
    else if (typeof dbOrgSchema.lists === 'object') orgSchema.lists = _cloneDeep(dbOrgSchema.lists)
    if(Array.isArray(orgSchema.lists)) {
        let lists = {}
        orgSchema.lists.forEach(l => {
            let key = l.key || l.tak || l.std_key
            if(l.std_key) {
                l.tak = l.std_key
                delete l.std_key
            }
            lists[key] = l
        })
        orgSchema.lists = lists
    }
    _forEach(orgSchema.lists, (l, k) => { 
        if(typeof l.options === 'string') l.options = JSON.parse(l.options)
        
        // convert legacy 'options' Array into an object
        if(Array.isArray(l.options)) {
            let options = {}
            l.options.forEach(o => {
                if(typeof o === 'string') o = { value: o, label: o, include: 'Y' }
                if(!o.include) o.include = 'Y'
                options[o.value] = o
            })
            l.options = options
        }
    })

    // org groups are stored in .setup.groups
    if (Array.isArray(dbOrgSchema.setup?.groups)) orgSchema.groups = dbOrgSchema.setup.groups

    // SET SETUP PARAMETERS - get setup parameters from org and apply defaults
    if(dbOrgSchema.setup) {
        schema.setup = _cloneDeep(dbOrgSchema.setup)
        if(!schema.setup.srs) schema.setup.srs = 'EPSG:4326'
        if(!schema.setup.imageSize) schema.setup.imageSize = '12'
        if(!schema.setup.imageCompress) schema.setup.imageCompress = 0.75
        if(!schema.setup.idSetup) schema.setup.idSetup = ['manual', 'auto', 'nfc-uid']
        if(!schema.setup.idType) schema.setup.idType = 'AssetID'
    }
    else {
        schema.setup = { 
            idType: 'AssetID', 
            idSetup: ['manual', 'auto', 'nfc-uid'],
            idAuto: 'up',
            imageSize: '12',
            imageCompress: 0.75,
            srs: 'EPSG:4326'
        }
    }

    // PARSE TRAKK STANDARD SCHEMA
    // create schema from config object
    let dbTrakkSchema = store.state.Db.config?.schema?.assets || {}
    let trakkSchema = {
        attributes: dbTrakkSchema.attributes,
        templates: dbTrakkSchema.templates,
        types: dbTrakkSchema.types,
        lists: dbTrakkSchema.lists,
        groups: dbTrakkSchema.groups,
        common: dbTrakkSchema.common || ['label', 'position', 'amsid', 'ds_inst', 'ds_ret', 'ds_exp', 'life_ya']
    }
    _forEach(trakkSchema, (o, k) => { 
        if(typeof o === 'string') trakkSchema[k] = JSON.parse(o)
    })
    _forEach(trakkSchema.groups, (g, k) => { 
        if(typeof g.types === 'string') g.types = JSON.parse(g.types)
    })
    
    console.log('DEPRECIATE l.options array in v5')
    _forEach(trakkSchema.lists, (l, k) => { 
        if(typeof o === 'string') trakkSchema.lists[k] = JSON.parse(l)
        
        // convert legacy 'options' Array into an object... temporary solution for backward compatibility
        if(Array.isArray(l.options)) {
            console.log('WARNING found depreciated l.options as array')
            let options = {}
            l.options.forEach(o => {
                if(typeof o === 'string') o = { value: o, label: o, include: 'Y' }
                if(!o.include) o.include = 'Y'
                options[o.value] = o
            })
            l.options = options
        }
        // trakkSchema.lists[k].tak = k
    })
    console.log('DEPRECIATE a.addAttrib array in v5')
    _forEach(trakkSchema.types, (t, k) => {
        // parse legacy 'addAttrib' array
        if(Array.isArray(t.addAttrib)) {
            console.log('WARNING found depreciated a.addAttrib as array')
            let addAttrib = {}
            t.addAttrib.forEach(a => {
                let tak = a.tak || a
                addAttrib[tak] = { include: 'Y' }
            })
            t.addAttrib = addAttrib
        }
    })
    console.log('DEPRECIATE t.attributes array in v5')
    _forEach(trakkSchema.templates, (t, k) => {
        // parse legacy 'attribute' array
        if(Array.isArray(t.attributes)) {
            console.log('WARNING found depreciated t.attributes as array')
            let attributes = {}
            t.attributes.forEach(a => {
                let tak = a.tak || a
                attributes[tak] = { include: 'Y' }
            })
            t.attributes = attributes
        }
    })
    // _forEach(trakkSchema.attributes, (o, k) => { o.tak = k })
    // _forEach(trakkSchema.types, (o, k) => { o.tak = k })
    // _forEach(trakkSchema.templates, (o, k) => { o.tak = k })
    
    
    // MERGE SCHEMAS
    // Merge trakk schema with org schema based on groups defined by orgSchema
    // Each schema object is merged separately so the "org" overwrites selected parts of 
    // the "standard" schema. 
    // 'include' flags are used in: 'types', 'type.addAttrib', 'template.attributes' and 'list.options' 
    // to include/exclude items from the schema.
    // 'index' flag is used in 'attributes' to sort the attributes and override the default schema 
    // 'addAttrib', 'attributes' and 'list.options' are presented as arrays
    
    // first compile list of asset types from selected 'groups' and 'types' in orgSchema
    let typesList = new Set()
    orgSchema.groups.forEach(key => {
        let types = (trakkSchema.groups[key] || {}).types || []
        types.forEach(t => typesList.add(t))
    })
    Object.keys(orgSchema.types).forEach(key => {
        typesList.add(key)
    })
    
    // then compile a list of templates and attributes used by those asset types
    let templateList = new Set()
    
    // add common attributes, amsID and all attributes configured by orgSchema
    let attributeList = new Set(trakkSchema.commonAttributes)
    if(schema.setup.amsID) attributeList.add(schema.setup.amsID)
    Object.keys(orgSchema.attributes).forEach(k => { 
        attributeList.add(k)
    })
    
    typesList.forEach(key => {
        let orgType = orgSchema.types[key] || {}
        let tak = orgType.tak || key
        let type = {}
        if(trakkSchema.types[tak]) Object.assign(type, { tak, key: tak }, trakkSchema.types[tak])
        
        // Merge orgType with nested addAttrib object
        Object.keys(orgType).forEach(k => { 
            if(k === 'addAttrib') {
                if(!type.addAttrib) type.addAttrib = {}
                Object.assign(type.addAttrib, orgType.addAttrib)
            }
            else type[k] = orgType[k] 
        })

        if(!type.template) return console.log('Found invalid schema type:', key, type);
        // add template to active list
        templateList.add(type.template);
        
        // convert nested attributes object to array, and add to attributeList
        let addAttrib = []
        _forEach(type.addAttrib, (a, k) => {
            if(a.include !== 'Y') return
            k = k.trim()
            addAttrib.push(k)
            attributeList.add(k)
        })
        type.addAttrib = addAttrib

        schema.types[key] = type
    })
    templateList.forEach(key => {
        let orgTemplate = orgSchema.templates[key] || {}
        let tak = orgTemplate.tak || key
        let template = {}
        if(trakkSchema.templates[tak]) Object.assign(template, { tak, key: tak }, trakkSchema.templates[tak])
        
        // Merge orgTemplate with nested addAttrib object
        Object.keys(orgTemplate).forEach(k => { 
            if(k === 'attributes') {
                if(!template.attributes) template.attributes = {}
                Object.assign(template.attributes, orgTemplate.attributes)
            }
            else template[k] = orgTemplate[k] 
        })
        
        if(!template.attributes) console.log('Found invalid schema template:', key);
        else trakkSchema.common.forEach(a => {
            if(!template.attributes[a]) template.attributes[a] = { include: 'Y' }
        })

        // convert nested attributes object to array, and add to attributeList
        let attributes = []
        _forEach(template.attributes || {}, (a, k) => {
            if(a.include !== 'Y') return
            k = k.trim()
            attributeList.add(k)
            attributes.push(k)
            if(a === 'name') console.log('name in', key)
        })
        template.attributes = attributes
        schema.templates[key] = template;
    })

    // use attributes to compile a list of selectionLists
    let attributesAdded = new Set()
    // console.log(orgSchema.attributes)
    attributeList.forEach(tak => {
        let orgAttribute = orgSchema.attributes[tak] || {}
        let stdAttribute = trakkSchema.attributes[tak] || null
        if(!stdAttribute && orgAttribute.tak) stdAttribute = trakkSchema.attributes[orgAttribute.tak]
        if(attributesAdded.has(tak)) return
        let attribute = {}
        if(stdAttribute) Object.assign(attribute, { tak, key: tak }, stdAttribute)
        Object.assign(attribute, orgAttribute)
    // if(attribute.key === 'model') {
        //     console.log(`missing type, '${tak}'`, { 
            //         orgAttribute, 
            //         stdAttribute,
            //         attribute
            //     });
            // }
        if(!attribute.key) attribute.key = tak // return console.log(`schema, found invalid attribute, '${tak}'`, orgAttribute);
        if(attribute.type === 'list') {
            let key = attribute.setup
            if(!schema.lists[key]) {
                let list = { key }
                let stdList = trakkSchema.lists[key] || null
                if(stdList) Object.assign(list, trakkSchema.lists[key])
                    
                // Merge orgList with nested 'options' object
                let orgList = orgSchema.lists[key] || {}
                Object.keys(orgList).forEach(k => { 
                    if(k === 'options') {
                        if(!list.options) list.options = {}
                        Object.assign(list.options, orgList.options)
                    }
                    else list[k] = orgList[k] 
                })
                
                // convert options to array
                let options = []
                _forEach(list.options, (o, k) => {
                    if(o.include !== 'Y') return
                    options.push({ value: o.value, label: o.label })
                })
                list.options = options
                if(!list.options) return console.log(`schema, found invalid list: '${key}'`);
                schema.lists[key] = list
            }
        }
        schema.attributes.push(attribute)
        attributesAdded.add(tak)
    })
    schema.attributes.sort(function(a, b) {
        if(typeof a.index === 'undefined') return 1
        if(typeof b.index === 'undefined') return -1
        return a.index - b.index;
    });
    // console.log({ std: trakkSchema, org: orgSchema, merge: schema })
    setSchema({ std: trakkSchema, org: orgSchema, merge: schema })
}

export {
    updateSchema
}
