import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index.js'
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { LogEvent } from '@/store/analytics.js'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const FWContainer = () => import('@/containers/FWContainer')
const DataContainer = () => import('@/containers/DataContainer')
const SchemaContainer = () => import('@/containers/SchemaContainer')

// Main App Views (user verified)
const ImageLoader = () => import('@/views/home/ImageLoader')
const Image = () => import('@/views/home/Image')
const Images = () => import('@/views/home/Images')
const Assets = () => import('@/views/home/Assets')
const Reports = () => import('@/views/home/Reports')
const Asset = () => import('@/views/home/Asset')
const AssetEdit = () => import('@/views/home/AssetEdit')
const Report = () => import('@/views/home/Report')
const ReportEdit = () => import('@/views/home/ReportEdit')
const Tags = () => import('@/views/home/Tags')
const Device = () => import('@/views/home/Device')
const Extract = () => import('@/views/home/Extract')
const MapLoader = () => import('@/views/home/MapLoader')
// const MapViewer = () => import('@/views/home/MapViewer')
const Admin = () => import('@/views/home/Admin')

// Management Views
const Map = () => import('@/views/manage/Map')
const System = () => import('@/views/manage/System')
const SchemaAttribute = () => import('@/views/manage/SchemaAttribute')
const SchemaTemplate = () => import('@/views/manage/SchemaTemplate')
const SchemaList = () => import('@/views/manage/SchemaList')
const SchemaType = () => import('@/views/manage/SchemaType')
const SchemaSetup = () => import('@/views/manage/SchemaSetup')
const Integration = () => import('@/views/manage/Integration')
const Account = () => import('@/views/manage/Account')
const TeamSelect = () => import('@/views/manage/TeamSelect')
const NewTeam = () => import('@/views/manage/NewTeam')

// Pages (public access)
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Help = () => import('@/views/pages/Help')
const Signup = () => import('@/views/pages/Signup')
const Logout = () => import('@/views/pages/Logout')
const Verify = () => import('@/views/pages/Verify')

const Wait = () => import('@/views/pages/Wait')
const Code = () => import('@/views/pages/Code')

Vue.use(Router)

let routes = [
  {
    path: '/home',
    name: 'HOME',
    component: TheContainer,
    children: [
      {
        path: 'extract',
        name: 'DXF EXPORT',
        component: Extract
      },
      {
        path: 'asset/add',
        component: AssetEdit,
        name: 'ADD ASSET',
        props: true
      },
      {
        path: 'asset/:aid',
        component: Asset,
        name: 'ASSET INFO',
        props: true
      },
      {
        path: 'asset/:aid/edit',
        component: AssetEdit,
        name: 'ASSET EDIT',
        props: true
      },
      {
        path: 'asset/:aid/edit/:eid',
        component: AssetEdit,
        props: true
      },
      {
        path: 'image',
        redirect: '/home/images'
      },
      {
        path: 'image/:iid',
        name: 'IMAGE',
        component: Image,
        props: true
      },
      {
        path: 'report/add',
        component: ReportEdit,
        name: 'ADD REPORT',
        props: true
      },
      {
        path: 'report/:rid',
        component: Report,
        name: 'REPORT INFO',
        props: true
      },
      {
        path: 'report/:rid/edit',
        component: ReportEdit,
        name: 'REPORT EDIT',
        props: true
      },
      {
        path: 'admin',
        name: 'ADMINISTRATION',
        component: Admin
      }
    ]
  },
  {
    path: '/home',
    name: 'FW',
    component: FWContainer,
    children: [
      {
        path: 'image/:iid/view',
        name: 'IMAGE VIEW',
        component: ImageLoader,
        props: true
      },
      {
        path: 'map',
        redirect: '/home/map/xxx'
      },
      {
        path: 'map/:mid',
        component: MapLoader,
        props: true
      },
      {
        path: 'map/:mid/@:cx|:cy|:cz',
        component: MapLoader,
        name: 'MAP',
        props: true
      },
    ]
  },
  {
    path: '/home',
    name: 'DATA',
    component: DataContainer,
    children: [
      {
        path: 'assets',
        redirect: 'assets/xxx'
      },
      // {
      //   path: 'assets/:rootid',
      //   component: Assets,
      //   props: true,
      // },
      // {
      //   path: 'assets/:rootid/:pid',
      //   component: Assets,
      //   props: true,
      // },
      // {
      //   path: 'assets/:rootid/:pid/:srch',
      //   component: Assets,
      //   props: true,
      //   name: 'ASSETS',
      // },
      {
        path: 'assets/:pid',
        component: Assets,
        props: true,
      },
      {
        path: 'assets/:pid/:srch',
        component: Assets,
        props: true,
        name: 'ASSETS',
      },
      {
        path: 'reports',
        redirect: 'reports/xxx/all/$20'
      },
      {
        path: 'reports/:rootid',
        redirect: 'reports/:rootid/all/%20'
      },
      {
        path: 'reports/:rootid/:typ',
        redirect: 'reports/:rootid/:typ/%20'
      },
      {
        path: 'reports/:rootid/:typ/:srch',
        component: Reports,
        props: true,
        name: 'REPORTS',
      },
      {
        path: 'images',
        redirect: 'images/xxx'
      },
      {
        path: 'images/:rootid',
        component: Images,
        props: true,
      },
      {
        path: 'images/:rootid/:srch',
        component: Images,
        props: true,
        name: 'IMAGES',
      },
      {
        path: 'tags',
        name: 'TAGS',
        component: Tags
      },
      {
        path: 'devices',
        name: 'DEVICES',
        component: Device
      },
      {
        path: 'device/:did',
        component: Device,
        name: 'DEVICE',
        props: true
      },
    ]
  },
  {
    path: '/manage',
    component: TheContainer,
    children: [
      {
        path: 'maps',
        name: 'MAPS',
        component: Map
      },
      {
        path: 'map/:mid',
        component: Map,
        name: 'MAP SETUP',
        props: true
      },
      {
        path: 'system',
        name: 'SYSTEM CONFIG',
        component: System
      },
      {
        path: 'devices',
        redirect: '/home/devices'
      },
      {
        path: 'device/:did',
        redirect: '/home/device/:did'
      },
      {
        path: 'integrations',
        name: 'INTEGRATIONS',
        component: Integration
      },
      {
        path: 'integration/:iid',
        component: Integration,
        name: 'INTEGRATION',
        props: true
      },
      {
        path: 'images',
        redirect: '/home/images'
      }
    ]
  },
  {
    path: '/manage',
    component: SchemaContainer,
    children: [
      {
        path: 'schema',
        redirect: '/manage/schema/setup'
      },
      {
        path: 'schema/setup',
        component: SchemaSetup,
      },
      {
        path: 'schema/types',
        component: SchemaType,
      },
      {
        path: 'schema/attributes',
        component: SchemaAttribute,
      },
      {
        path: 'schema/templates',
        component: SchemaTemplate,
      },
      {
        path: 'schema/template/:sid',
        component: SchemaTemplate,
        props: true
      },
      {
        path: 'schema/type/:sid',
        component: SchemaType,
        props: true
      },
      {
        path: 'schema/lists',
        component: SchemaList,
      },
    ]
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: TheContainer,
    children: [
      {
        path: 'faq',
        name: 'Page404',
        component: Page404
      },
      {
        path: 'gettingStarted',
        name: 'Page500',
        component: Page500
      }
    ]
  },
  {
    path: '/code/',
    component: TheContainer,
    children: [
      {
        path: ':code',
        name: 'Code',
        component: Code,
        props: true
      },
    ]
  },
  {
    path: '/newteam',
    name: 'NewTeam',
    component: NewTeam
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/teamselect',
    name: 'TeamSelect',
    component: TeamSelect
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    redirect: '/signup/select'
  },
  {
    path: '/signup/:plan',
    name: 'Signup',
    component: Signup,
    props: true
  },
  {
    path: '/help',
    name: 'HELP',
    component: Help
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify
  },
  {
    path: '/wait',
    name: 'Wait',
    component: Wait
  }
]

const router = new Router({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  mode: 'hash', // https://router.vuejs.org/api/#mode,
  scrollBehavior () {
    let container = document.getElementById('content-container')
    if(container) container.scrollIntoView({ behavior: 'smooth' });
  }
});

router.beforeEach((to, from, next) => {
  // console.warn('router: ', to.path)
  // console.log('loggedIn', store.state.Auth.user.loggedIn)
  // console.log('verified', store.state.Auth.user.data.verified)

  // allow public paths
  let allowedPaths = ['/code', '/signup', '/help', '/logout']
  for(let i = 0; i < allowedPaths.length; i++) {
    if (to.path.startsWith(allowedPaths[i])) return next()
  }

  if (store.state.Auth.authReady === false 
    || store.state.Util.nav.loading) {
    // console.log('saving path')
    // console.log('path', to.path)
    if (['/wait', '/logout'].includes(to.path)) return next()
    store.commit('SET_NAV', { waiting: to.path }) 
    return next('/wait')
  } 
  else if (!store.state.Auth.user.loggedIn) {
    // console.log('not loggedIn')
    if (['/', '/register'].includes(to.path)) return next()
    store.commit('SET_NAV', { waiting: to.path })
    return next('/')
  }
  else if (!store.state.Auth.user.data.verified || !store.state.Auth.configUser.agreedTerms) {
    // console.log('not verified')
    if (['/', '/register', '/verify'].includes(to.path)) return next()
    store.commit('SET_NAV', { waiting: to.path })
    return next('/verify')
  }
  else {
    // fallback to clear NFC actions on new route
    // consider checks to clear only root path if issues arise
    store.dispatch('nfcClearAll')
    return next() 
  }
});

App.addListener('appUrlOpen', function (event) {
  // Example url: https://app.trakkassets.com/#/home/assets/xxx
  // slug = /home/assets/xxx
  const slug = event.url.split('.com/#').pop();
  // We only push to the route if there is a slug present
  if (slug) {
    let page = slug.startsWith('/home') ? slug.substring(5).split('/')[0] : slug.substring(1).split('/')[0]
    LogEvent('use_feature', { feature: 'deep_link', page, slug })
    router.push(slug);
  }
});

export default router 
